import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';
import Container from '@csv/styleguide/src/elements/container/container';
import { graphql } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { mapEdgesToNodes } from '../lib/helpers';
import Heading from '@csv/styleguide/src/elements/heading/heading';

const SponsorPageGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 280px));
  grid-gap: 32px;
  justify-content: center;
  align-items: start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Sponsor = styled.li`
  display: flex;
  flex-direction: column;

  .gatsby-image-wrapper {
    order: 1;
    margin-bottom: 24px;
  }
  h2 {
    order: 2;
    margin-bottom: 8px;
    a {
      text-decoration: none;
      color: #2041a3;
    }
  }
  p {
    order: 3;
    font-size: 0.875rem;
    margin-top: 0;
    color: #081f2c;
  }
`;

interface SponsorPageProps {
  data: any;
}

export const query = graphql`
  query allSponsorsQuery {
    sponsors: allSanitySponsor {
      edges {
        node {
          name
          link
          description
          image {
            asset {
              fixed(width: 260) {
                src
                srcSet
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;

function Sponsors({ data }: SponsorPageProps) {
  const sponsorNodes = data && data.sponsors && mapEdgesToNodes(data.sponsors);
  const hasSponsors = sponsorNodes && sponsorNodes.length;

  return (
    <Layout>
      <SEO
        title="Sponsors"
        description="De sponsors van CSV Handbal Castricum"
      />
      <PageHeaderComposition
        title={'Sponsors'}
        byline="Samen met onze sponsors naar sportief succes! Neem contact op voor de sponsormogelijkheden."
      />
      <Container>
        {hasSponsors && (
          <>
            <p style={{ marginBottom: 48 }}>
              Bent u geïntereseerd in de sponsormogelijken bij CSV Handbal? Neem
              dan contact op met Anna Dekker en Hester van Urk van de
              sponsorcomissie via:{' '}
              <a href="mailto:sponsoring@csvhandbal.nl">
                sponsoring@csvhandbal.nl
              </a>
            </p>
            <SponsorPageGrid>
              {sponsorNodes.map(sponsor => {
                return (
                  <Sponsor key={sponsor.name}>
                    <Heading as="h2" variant="h6">
                      <a
                        href={sponsor.url || '#'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {sponsor.name}
                      </a>
                    </Heading>
                    {sponsor.image.asset && sponsor.image.asset.fixed && (
                      <Img
                        // Typecasting is needed here, see: https://github.com/sanity-io/gatsby-source-sanity/issues/57
                        fixed={sponsor.image.asset.fixed as FixedObject}
                        alt={`${sponsor.name} logo`}
                      />
                    )}
                    {sponsor.description && <p>{sponsor.description}</p>}
                  </Sponsor>
                );
              })}
            </SponsorPageGrid>
          </>
        )}
      </Container>
    </Layout>
  );
}

export default Sponsors;
